<template>
  <base-page
    :title="$t('algorithmPlatformManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.arithmeticPlatformFindArithmeticPlatforms"
      :multiple="false"
      :highlightCurrentRow="true"
      :columns="columns"
      :defaultParams="params"
      v-model="tableRow"
    >
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <!-- lang:修改 -->
        <el-button
            v-if="$page.showBtn('ARITHMETIC_PLATFORM_MANAGE_UPDATE')"
            type="text"
            @click="editMethod(row)"
        >
          {{ $t('functionEnum.ARITHMETIC_PLATFORM_MANAGE_UPDATE') }}
        </el-button>
        <!-- lang:删除 -->
        <el-button
            v-if="$page.showBtn('ARITHMETIC_PLATFORM_MANAGE_DEL')"
            type="text"
            @click="deleteMethod(row)"
        >
          {{ $t('functionEnum.ARITHMETIC_PLATFORM_MANAGE_DEL') }}
        </el-button>
        <!-- lang:显示 -->
        <el-button
            v-if="$page.showBtn('ARITHMETIC_PLATFORM_MANAGE_VIEW')"
            type="text"
            @click="viewMethod(row)"
        >
          {{ $t('functionEnum.ARITHMETIC_PLATFORM_MANAGE_VIEW') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'


export default {
  components: {
    Detail,
  },
  data() {
    let _that = this
    return {
      // 搜索
      formData: {
      },
      config: [],
      // 显示表单
      showDetail:false,
      showDialogImport:false,
      // 表格页
      tableRow: null,
      tableRows: [],
      params: {
        companyUid: this.$store.getters.company.uid,
        isAll: 'YES'
      },
      columns: [
        // lang:名称
        {
          label: this.$t('algorithmPlatformManage.name'),
          prop: 'name',
        },
        // lang:类型
        {
          label: this.$t('algorithmPlatformManage.recognitionStartApiId'),
          prop: 'recognitionPreLoadApi',
          callback(row) {
            return row.recognitionPreLoadApi.externalApi.name
          },
        },
        // lang:类型
        {
          label: this.$t('algorithmPlatformManage.recognitionEndApiId'),
          prop: 'recognitionRelaseApi',
          callback(row) {
            return row.recognitionRelaseApi.externalApi.name
          },
        },
        // lang:类型
        {
          label: this.$t('algorithmPlatformManage.recognitionAlgorithmIdApiId'),
          prop: 'recognitionAlgorithmIdsApi',
          callback(row) {
            return row.recognitionAlgorithmIdsApi.externalApi.name
          },
        },
        // lang:类型
        {
          label: this.$t('algorithmPlatformManage.recognitionAlgorithmRecognitionVersionsApiId'),
          prop: 'recognitionAlgorithmRecognitionVersionsApi',
          callback(row) {
            return row.recognitionAlgorithmRecognitionVersionsApi.externalApi.name
          },
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '200px',
        },
      ],
    }
  },
  methods: {
    // 关闭详情页回调
    closePage(cmd) {
      this.showDetail = false
      this.showRenewal = false
      this.search()
    },
     // 权限按钮点击回掉
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'import' && method !== 'add' && method !== 'add_arithmetic_api' && this.tableRows.length === 0) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break

        case 'export':
          this.exportMethod()
          break
        case 'import':
          this.showDialogImport = true
          break
        default: // do something
      }
    },
    editMethod(row) {
      this.tableRow = row
      this.$store.state.cmd = 'update'
      this.showDetail = true
    },
    viewMethod(row) {
      this.tableRow = row
      this.$store.state.cmd = 'view'
      this.showDetail = true
    },
    exportMethod() {
      const ids = this.tableRows.map(item => item.id)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateExport,
        data: {ids}
      }).then(res => {
        window.location.href = res
      })
    },
    // 导入成功回掉
    importConfirm() {
      this.showDialogImport = false
      this.search()
    },
    deleteMethod(row) {
      this.tableRow = row
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticPlatformDelete,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.search()
            })
          })
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
  },
}
</script>
