<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
    >
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :defaultParams="dialogDefaultParams"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
      :multiple="false"
    >
    </dialog-table-list>
  </div>
</template>

<script>

import {arithmeticPlatformAdd, arithmeticPlatformUpdate, findExternalAiApiData, switchExternalAiApiData} from "@/api";

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:名称
        {
          label: this.$t('algorithmPlatformManage.name'),
          prop: 'name',
          hidden: false,
          rules: {
            noNull: true,
          }
        },
        // lang:识别预加载API
        {
          tag: 'dialog-define',
          label: this.$t('algorithmPlatformManage.recognitionStartApiId'),
          prop: 'recognitionStartApiId',
          // hidden: true,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            }
          },
        },
        // lang:识别资源释放API
        {
          tag: 'dialog-define',
          label: this.$t('algorithmPlatformManage.recognitionEndApiId'),
          prop: 'recognitionEndApiId',
          // hidden: true,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            }
          },
        },
        // lang:识别算法ID列表API
        {
          tag: 'dialog-define',
          label: this.$t('algorithmPlatformManage.recognitionAlgorithmIdApiId'),
          prop: 'recognitionAlgorithmIdApiId',
          // hidden: true,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            }
          },
        },
        // lang:识别算法ID版本API
        {
          tag: 'dialog-define',
          label: this.$t('algorithmPlatformManage.recognitionAlgorithmRecognitionVersionsApiId'),
          prop: 'recognitionAlgorithmRecognitionVersionsApiId',
          // hidden: true,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            }
          },
        },
      ],
      enableObj: this.$util.listToObj(this.$dict.enable()),
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: [],
      workFlowStepTemplateTypeEnum: [],
      workFlowElementTypeEnum: [],
      workFlowElementFormTypeEnum: [],
      fileSrc: null,
      fileId: null,
      selectedType: []
    }
  },
  async mounted() {
    this.setDefaultValue()
    this.findRecognitionAlgorithmTypes()
  },
  methods: {
    findRecognitionAlgorithmTypes() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findRecognitionAlgorithmTypes,
        data: {}
      }).then(res => {
        // window.location.href = res
        console.log(res)
        const algorithmTypes = this.$dict.algorithmType()
        const options = []
        res.forEach(v => {
          const type = algorithmTypes.filter(i => i.value === v)
          if(type.length > 0) {
            options.push( type[0])
          } else {
            options.push({
              label: v,
              value: v
            })
          }
        })
        console.log('res', options)
        this.config[1].tagProps.options = options
      })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.recognitionStartApiId = this.tableRow.recognitionPreLoadApi.externalApi
          this.formData.recognitionEndApiId = this.tableRow.recognitionRelaseApi.externalApi
          this.formData.recognitionAlgorithmIdApiId = this.tableRow.recognitionAlgorithmIdsApi.externalApi
          this.formData.recognitionAlgorithmRecognitionVersionsApiId = this.tableRow.recognitionAlgorithmRecognitionVersionsApi.externalApi
          console.log('this.formData',this.formData)
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      console.log('confirm', this.formData)
      console.log('cmd', this.cmd)
      const formData = this.$util.copyData(this.formData)
      formData.recognitionStartApiId = formData.recognitionStartApiId.id
      formData.recognitionEndApiId = formData.recognitionEndApiId.id
      formData.recognitionAlgorithmIdApiId = formData.recognitionAlgorithmIdApiId.id
      formData.recognitionAlgorithmRecognitionVersionsApiId = formData.recognitionAlgorithmRecognitionVersionsApiId.id
      console.log('formData', formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'update':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticPlatformAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },
    editMethod(formData) {
      formData.id = this.tableRow.id
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticPlatformUpdate,
        data: formData,
      }).then(() => {
        // lang:编辑成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'edit')
      })
    },

    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('algorithmPlatformManage.selectApi')
      this.dialogUrl =  '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dataCenterFindList
      this.dialogDefaultParams = {
        companyUid: this.$store.getters.company.uid,
        scope: 'CHANNEL_LINE',
        searchAll: 'YES'
      }
      this.dialogColumns = [
        // lang:外部API名称
        {
          label: this.$t('algorithmPlatformManage.apiName'),
          prop: 'name',
        },
        // lang:uid
        {
          label: 'ID',
          prop: 'id',
        },
      ]
      this.dialogConfig = [
      ]
      this.showDialogTableList = true
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
      if (this.formData.recognitionAlgorithmType === 'EXTERNAL_AI_IMG') {
        this.config[this.config.length -1].hidden = false
        const data = {
          id: row.id
        }
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.switchExternalAiApiData,
          data,
        }).then((res) => {
          this.formData.recognitionLabelDataList = res
        })
      }
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    addRecognitionType() {
      const data = {
        name: '',
        confidence: 0,
        value: '',
        type: 0
      }
      if(this.formData.recognitionLabelDataList && this.formData.recognitionLabelDataList instanceof Array) {
        this.formData.recognitionLabelDataList.push(data)
      } else {
        this.formData.recognitionLabelDataList = []
        this.formData.recognitionLabelDataList.push(data)
      }
    },
    delRecognitionType() {
      if(this.selectedType.length > 0) {
        this.formData.recognitionLabelDataList = this.formData.recognitionLabelDataList.filter((_, index) => this.selectedType.indexOf(index) === -1)
        this.selectedType = []
      }
    },
    onChange(val) {
      const isExists = this.selectedType.filter(i => i === val)
      if(isExists.length > 0) {
        this.selectedType = this.selectedType.filter(i => i !== val)
      } else {
        this.selectedType.push(val)
      }
      console.log(this.selectedType)
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/theme.scss';
.upload-no-img {
  width: 200px;
  height: 200px;
  font-size: 36px;
  line-height: 200px;
  text-align: center;
  border: 1px dashed #cccccc;
  cursor: pointer;
  border-radius: 4px;
}
:hover.upload-no-img {
  border: 1px dashed blue;
  color: blue;
}
.upload-has-img {
  width: 200px;
  height: 200px;
  line-height: 200px;
  cursor: pointer;
  border-radius: 4px;
  object-fit: contain;
  background: black;
}
.element-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  .field {
    width: 78%;
    border: 5px solid #eeeeee;
    background: #eeeeee;
    margin-right: 5px;
    .field-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .el-input:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .opt {
    i {
      margin-right: 10px;
      cursor: pointer;
      color: $--color-primary;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
.recognition-type {
  &__list {
    .item {
      margin-top: 10px;
      background: #efefef;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      &__left {
        .field {
          //display: flex;
          //align-items: center;
        }
      }
    }
  }
}
</style>
